/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import gtgkick from "../../images/illustrations/gtog_kickstart.png"
import gtgstuck from "../../images/illustrations/gtog_stuck.png"
import gtggreat from "../../images/illustrations/gtog_gtog.png"
import styled from "styled-components"
import { Link } from "gatsby"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="mx-auto p-6 md:pt-12 sm:p-8">



<Header>
          <div>


            <h2
              style={{
                fontWeight: 800,
              fontSize: 28,
                
              }}
              className="tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl mb-2"
            >
              SET &middot; FOCUS &middot; ACHIEVE
            </h2>
      
            <h2
              style={{
                fontWeight: 800,
                fontSize: 36,
                color: "#cc3366",
              }}
              className="uppercase tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl"
            >
              WHEREVER YOU ARE
            </h2>
            <p
              style={{
                fontWeight: 600,
                fontSize: 20,
              }}
              className="mt-2 text-xs sm:text-sm md:text-md para"
            >
              The goal is to move forward and feel fulfilled both in Personal and Professional Life.
            </p>
          </div>
          <div className="md:flex p-4 justify-center">
            <div className="ml-4 mr-4 flex flex-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 ">
                <img
             style={{ width: 220 }}
                    className="h-auto"
            src={gtgkick}
            alt="Coaching Benefits"
            />
            </div>
            <div className="ml-4 mr-4 flex flex-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 ">
                <img
             style={{ width: 220 }}
                    className="h-auto"
            src={gtgstuck}
            alt="Coaching Benefits"
            />
            </div>
            <div className="ml-4 mr-4 flex flex-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 ">
                <img
             style={{ width: 220 }}
                    className="h-auto"
            src={gtggreat}
            alt="Coaching Benefits"
            />
            </div>
          </div>
          </Header>
          <div className="text-center mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{ backgroundColor: "#f2f2f2"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left" }} >
                <span style={{ color: "#cc3366" }}>Entrepreneurs </span> and Startups
              </h1>
            </div>
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{  backgroundColor: "#e3e3e3"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left"  }} >
                <span style={{ color: "#cc3366" }}>Students and </span>Parents
              </h1> 
            </div>
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{ backgroundColor: "#f4f4f4"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left"  }} >
                <span style={{ color: "#cc3366" }}>Well-being and  </span>Women
              </h1> 
            </div>
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{backgroundColor: "#e2e2e2"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left"  }} >
                <span style={{ color: "#cc3366" }}>Career and </span> Performance
              </h1> 
            </div>
          </div>

            


              <div className="md:flex p-4 justify-center" style={{ textAlign: "center"}}>
                <div className="flex-col w-full" >
                  <div className="para mb-2 mt-4" style={{ fontWeight: 800, fontSize: 22, color: "#333333",}}>
                    BeingZone Coach guides you, strategizes with you, and brings accountability to your goal
                  </div>
                  <div className="uppercase" style={{ fontWeight: 800, fontSize: 24, color: "#cc3366",}}>
                    Schedule a trial Coaching session
                  </div>
                  <div className="font-bold mb-8 para" style={{ fontSize: 18, color: "#333333",}}>
                    to see how it is working for you
                  </div>
                  <div>
                      <Link
                        to={"/pricing"}
                        className="mt-2 mb-8 cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none font-bold py-3 px-6 uppercase rounded" style={{fontSize: 18, fontWeight: 600 }}
                      >
                        GET STARTED
                      </Link>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
    )
  }
}
