/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import img_takea from "../../images/illustrations/hiw_takeassessment_screen.png"
import img_gcoach from "../../images/illustrations/hiw_getcoach_screen.png"
import img_ssession from "../../images/illustrations/hiw_startsession_screen.png"
import { Link } from "gatsby"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const features = [
  {
    heading: "1",
    image: img_gcoach,
    subheading: "Chemistry session",
    notes: "Know the Coach & Coaching, Coach will understand you and your goals"
  },
  {
    heading: "2",
    image: img_takea,
    subheading: "Take an assessment",
    notes: "Discover yourself, your strengths, and gaps to enhance your life to the best"
  },
  {
    heading: "3",
    image: img_ssession,
    subheading: "Start your session",
    notes: "Become better and strong through Coaching, grow your potential every session"
  },
]

const renderFeature = () => {
  let featuresData = []
  features.forEach((feature, index) => {
    featuresData.push(
      <div
        key={index}
        className="flex flex-col text-center w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-4"
      >
        <div className="self-center">
          <div
            className="text-white flex justify-center"
            style={{
              backgroundColor: "#000000",
              width: "76px",
              height: "76px",
              borderRadius: "50%",
            }}
          >
            <div className="self-center font-extrabold" style={{ fontSize: 32 }}>{feature.heading}</div>
          </div>
          
        </div>

        <div className="self-center">
        <img
            className="h-auto"
            src={feature.image}
            alt="illustration"
            style={{
                width: 340, padding:10,
              }}
          />
          <p
            style={{ width: 300, fontSize: 24, fontWeight: "bold", }}
            className="text-white mt-3  ml-8 mr-8 para"
          >
            {feature.subheading}
          </p>
          <p
            style={{ width: 300, fontSize: 16 }}
            className="text-white mt-3  ml-8 mr-8 para"
          >
            {feature.notes}
          </p>
        </div>
      </div>
    )
  })
  return featuresData
}

const Works = ({ offset }) => (
  <div style={{ backgroundColor: "#663399" }}>
    <div className="relative overflow-hidden">
      <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
        <Header>
          <h1 className="uppercase text-white text-center	 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl">
            How it works
          </h1>

          <h3 className="uppercase text-white text-center para mt-2 mb-2">Coaching is the journey created by you and coach together</h3>
          <h2 className="uppercase text-white text-center para mt-4 ">THE COACHING PROCESS</h2>
        </Header>
        <div className="mt-8 mb-8 flex flex-wrap">{renderFeature()}</div>

        <h2
          style={{
            fontWeight: 800,
            fontSize: 28,
          }}
          className="uppercase text-white text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
        >
          Redesign your process, strategy, and life
        </h2>

        <p
          style={{
            fontSize: 14,
          }}
          className="text-white text-center para mt-2"
        >
          Professionally certified coaches guide you to lead success in your life.
        </p>

        <div className="text-center mt-8">
          <Link
            to={"/pricing"}
            className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none  font-bold py-3 px-6 rounded"
          >
            Get Started Now
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default Works
