import React from "react"
import Layout from "../components/layout"
import Hero from "../components/for-individuals/hero"
import Iquote from "../components/for-individuals/quote"
import Coachingareas from "../components/for-individuals/coachingareas"
import Howitworks from "../components/how-it-works-individuals/works"


export default function forIndividuals() {
  return (
    <Layout>
      <Hero />
      <Iquote />
      <Coachingareas />
      <Howitworks />
    </Layout>
  )
}
